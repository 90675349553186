header {
  &:before {
    border-bottom: 1px solid #eaecef;
  }
  .logo {
    background-image: url(/img/logo_color_black.png);
    &:hover {
      background-image: url(/img/logo_color_black.png);
    }
  }
  nav {
    .menu {
      li {
        a, .a {
          color: #5d5c65;
          font-weight: 600;
        }
        &:last-of-type {
          a {
            color: #ffffff;
            font-weight: 400;
            &:after {
              background: $osn-col1;
            }
          }
        }
      }
    }
  }
  .burger {
    div {
      background: $osn-col1;
    }
  }
}