header {
    .container {
        &:before {
            background: rgba(0,0,0,.1);
        }
        .logo {
            background-image: url(/img/logo_color_black.png);
        }
        nav {
            ul {
                li {
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobi_start) {
    header {
        .container {
            .burger {
                background: linear-gradient(0deg, #000, #000) no-repeat 0 0 / 100% $m2, linear-gradient(0deg, #000, #000) no-repeat 0 center / 100% $m2, linear-gradient(0deg, #000, #000) no-repeat 0 100% / 100% $m2;
            }
        }
    }
}
