@import "all_page";
@import "header";
@import "header-white";
@import "new/header-white";

html {
    height: 100%
}

body {
    background-color: #fff;
    height: 100%;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    #p4p-scene {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .p4p-text-layer {
            color: #dadde1;
            left: 50%;
            position: absolute;
            top: 65%;
            width: 870px;
            transform: translateX(-50%);
            .p4p-row {
                display: flex;
                flex-direction: column;
                p {
                    &:nth-of-type(1) {
                        font-size: 60px;
                        font-weight: 900;
                        text-align: center;
                    }
                    &:nth-of-type(2) {
                        font-size: 20px;
                        color: #dadde1;
                        margin-top: 20px;
                    }
                }
            }
            .p4p-column {
                flex: 1;
            }
        }
        .layer {
            width: 100%;
            height: 100%;
            @for $i from 2 through 7 {
                &:nth-child(#{$i}) {
                    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=(#{$i + 2} * 10));
                    opacity: #{$i + 2} / 10;
                }
            }
            svg {
                fill: #fff;
                position: absolute
            }
            &:nth-child(1) .p4p-parallax-background {
                background-color: #dadde1;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0
            }
            a {
                color: $osn-col3;
            }
        }
    }
    #p4p-shape {
        display: none;
        &.visible {
            display: block
        }
    }
}

@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer {
        background-color: #fff;
        bottom: 0;
        top: auto;
        left: 0;
        height: auto;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        -moz-transform: none;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none
    }
}

@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer .p4p-row {
        display: block
    }
}

@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer .p4p-column {
        flex: none
    }
}

#p4p-scene .p4p-text-layer .p4p-column + .p4p-column {
    margin-left: 20px
}

@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer .p4p-column + .p4p-column {
        margin-left: 0
    }
}

@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer h1 {
        font-size: 2em
    }
}


@media screen and (max-width: 768px) {
    #p4p-scene .p4p-text-layer a {
        margin-bottom: 10px;
        margin-top: 0px
    }
}

@media screen and (max-width: 768px) {
    #p4p-scene .layer:nth-child(1) .p4p-parallax-background {
        width: 100%;
        height: 40%
    }
}
